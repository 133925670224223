import * as React from "react";
import { navigate } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { Container } from "../styles/UI";
import styled from "styled-components";

import taxcelBgLogo from "../images/home/logo-negative.svg";
import Button from "../DesignSystem/Button";

// import ProdutosHome from "../pagesFiles/home/produtos";

const Hero = styled.section`
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 120px;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;

  .buttons {
    button {
      margin-top: 16px;
    }
    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: flex;
      align-items: center;

      button:first-child {
        margin-right: 24px;
      }
    }
  }

  button:last-child:hover {
    background: var(--color-white);
  }

  button:last-child {
    box-shadow: 0px 8px 16px -4px #05185b;
  }

  ${Container} {
    .cta {
      position: relative;
      z-index: 10;

      h1 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
        line-height: 1.25;
      }

      p {
        font-weight: 350;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }

    .illustration {
      z-index: 1;
      margin-top: 0px;
      position: relative;

      .bg-logo {
        position: absolute;
        z-index: 0;
        height: 800px;
        right: -180px;
        top: -240px;

        @media (min-width: ${(p) => p.theme.bp.tablet}) {
          left: -100px;
          top: -580px;
          height: 1360px;
        }
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-height: 820px;
      display: grid;
      /* grid-template-columns: repeat(2, 1fr); */
      grid-template-columns: minmax(600px, 1fr) 1fr;
      grid-column-gap: 32px;
      align-items: center;

      h1 {
        font-size: 35px;
        margin-top: 64px;
      }
    }
  }
`;

const handleClick = () => {
  navigate("/");
};
const handleClickBack = () => {
  navigate(-1);
};

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Sobre" />
      <Hero>
        <Container>
          <div className="cta">
            <h1>Página não encontrada</h1>
            <p>
              Verifique o endereço da página ou, caso tenha sido redirecionado
              através de um link, entre em contato conosco.
            </p>
            <div className="buttons">
              <Button
                ghost
                bg="secondary"
                color="secondary"
                onClick={handleClickBack}
              >
                Voltar
              </Button>
              <Button bg="secondary" color="primary" onClick={handleClick}>
                Ir para a Página Inicial
              </Button>
            </div>
          </div>
          <div className="illustration">
            <img src={taxcelBgLogo} alt="" className="bg-logo" />
          </div>
        </Container>
      </Hero>
      {/* <ProdutosHome /> */}
    </Layout>
  );
};

export default NotFoundPage;
